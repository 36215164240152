import { Box, Grid } from "@mui/material";
import Styled from "@emotion/styled";
import { ColorTheme } from "./styles/color";

export const HowItWorkCardContainer = Styled(Box)`
    border-radius: 16px;
    background: var(--Primary-25, #F4FDFF);
    height: 100%;
    position: relative;
    box-shadow: 0px 4px 28.9px 2px rgba(16, 24, 40, 0.12);
`;

export const InfoSectionContainer = Styled(Grid)`
    border-radius: 16px;
    background: ${ColorTheme.grayNeutral[600]};
`;
