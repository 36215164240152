import { Box, Grid } from "@mui/material";
import FaqBackground from "../assets/faqbackground.png";
import { PrimaryButton } from "../common/button/ButtonStyle";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";

const FaqSection = () => {
    return (
        <Grid container width={"100%"}>
            <Grid item xs={12} width={"100%"}>
                <Box
                    style={{
                        backgroundImage: `url(${FaqBackground})`,
                        padding: 54,
                        borderRadius: 40,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <Box top={0} width={"100%"} display={"flex"} justifyContent={"center"}>
                        <Box>
                            <DisplayText
                                textAlign={"center"}
                                size={"sm"}
                                textheight={"38px"}
                                textcolor={ColorTheme.base.white}
                                textweight={"bold"}
                            >
                                Frequently asked <span style={{ color: ColorTheme.secondary[600] }}>questions</span>
                            </DisplayText>
                            <Text
                                size={"md"}
                                mt={"12px"}
                                textAlign={"center"}
                                textcolor={ColorTheme.base.white}
                                textweight={"regular"}
                            >
                                Everything you need to know to get started offering Slice Pay to your customers.
                            </Text>

                            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                                <Box width={"180px"}>
                                    <PrimaryButton
                                        onClick={() => {
                                            window.open("https://intercom.help/slice-pay/en/", "_blank");
                                        }}
                                        style={{ width: "180px" }}
                                    >
                                        View FAQs
                                    </PrimaryButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FaqSection;
