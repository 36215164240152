import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import skyLogo from "./assets/logo-blue.svg";
import FaqSection from "./component/FaqSection";
import Footer from "./component/Footer";
import HowItWorksSection from "./component/HowItWorksSection";
import SkyPageHeader from "./component/SkyPageHeader";
import SkyPagePartners from "./component/SkyPagePartners";
import { logAmplitudeEvent } from "./util/logAmplitudeEvent";

const SkyPage = () => {
    useEffect(() => {
        logAmplitudeEvent("sky-page-viewed");
    }, []);
    return (
        <Grid container bgcolor={"#F7F5F0"} py={"40px"} justifyContent={"center"} className="page-skypay-for-agents">
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"start"}>
                            <img src={skyLogo} alt={"skylogo"} height={"130px"} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} py={"32px"}>
                        <SkyPageHeader />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <HowItWorksSection />
            </Grid>
            <Grid item xs={10} my={["20px", "20px", "48px"]}>
                <SkyPagePartners />
            </Grid>
            <Grid item xs={10} my={["20px", "20px", "48px"]}>
                <FaqSection />
            </Grid>
            <Grid item xs={10} my={["20px", "20px", "48px"]}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default SkyPage;
