import { GlowingIcon, HowItWorkCardContainer } from "../Style";
import { Box } from "@mui/material";
import { Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import ArrowCurveIcon from "../assets/arrow_curve_icon.svg";
import { useGetWindowSize } from "../common/hook/WindowSize";

const HowItWorkCard = ({ title, description, img, index }) => {
    const { desktopView } = useGetWindowSize();
    return (
        <HowItWorkCardContainer px={"30px"} py={["30px", "30px", "0px"]}>
            {index !== 4 && desktopView && (
                <Box
                    display={"flex"}
                    position={"absolute"}
                    zIndex={9999}
                    bottom={index !== 2 ? "-38px" : undefined}
                    top={index === 2 ? "-36px" : undefined}
                    right={"-50px"}
                    justifyContent={"flex-end"}
                >
                    <img
                        style={{
                            transform: index === 2 ? "rotateX(180deg)" : ""
                        }}
                        src={ArrowCurveIcon}
                        alt={"arrow_curve"}
                    />
                </Box>
            )}

            <Box display={"flex"} justifyContent={"center"}>
                <GlowingIcon>
                    <Text size={"lg"} textcolor={ColorTheme.base.white} textweight={"medium"}>
                        {index}
                    </Text>
                </GlowingIcon>
            </Box>
            <Box display={"flex"} mt={["0px", "0px", "20px"]} justifyContent={"center"}>
                <img src={img} alt={title} />
            </Box>

            <Text my={"12px"} width={"90%"} textAlign={"center"} size={"xl"} textweight={"semiBold"}>
                {title}
            </Text>
            <Text textAlign={"center"} size={"md"} textweight={"regular"}>
                {description}
            </Text>
        </HowItWorkCardContainer>
    );
};

export default HowItWorkCard;
