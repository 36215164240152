import { HashRouter, Route, Routes } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

import SkyPayCustomer from "./sky-page-customers/SkyPayCustomer";
import SkyPageAgents from "./sky-page-agents/SkyPageAgents";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        amplitude.init("c57439f4d87970a1c3f94fc75a0210f0", {
            defaultTracking: false
        });
    }, []);
    return (
        <HashRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <meta http-equiv="refresh" content="0; url=/#/for-customers"></meta>
                            Redirecting..
                        </>
                    }
                />
                <Route path="/for-travel-agents" element={<SkyPageAgents />} />
                <Route path="/for-customers" element={<SkyPayCustomer />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
