import { Grid, Box } from "@mui/material";
import SkyPageDesktop from "../assets/skypageDesktop.png";
import SkyPageMobile from "../assets/skypageMobile.png";
import SkypageLogo from "../assets/logo-white.svg";
import { ColorTheme } from "../styles/color";
import CircleLap from "../assets/skypageCircle.svg";
import { SkyPageTravelInfoList, windowScroll } from "../util";
import CheckIcon from "../assets/check.svg";
import { logAmplitudeEvent } from "../log-amplitude-event";
import { useGetWindowSize } from "../common/hook/WindowSize";
import { DisplayText, Text } from "../common/typography/Style";
import { PrimaryButton } from "../common/button/ButtonStyle";
import { useNavigate } from "react-router-dom";

const SkyPageHeroBanner = () => {
    const { desktopView } = useGetWindowSize();
    const navigate = useNavigate();
    return (
        <Grid
            container
            bgcolor={["#1F2A37", "#1F2A37", undefined]}
            borderRadius={["0px 0px 0px 50px", "0px 0px 0px 50px", "0px 0px 0px 100px"]}
        >
            <Grid py={"30px"} item xs={12} bgcolor={"#1F2A37"} display={["block", "block", "none"]}>
                <Box display={"flex"} justifyContent={"center"}>
                    <img width={"147px"} src={SkypageLogo} alt={"logo"} />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid pt={["0px", "0px", "76px"]} container justifyContent={"center"}>
                    <Grid item xs={12} md={8} px={"20px"}>
                        <Grid container>
                            <Grid item xs={12} display={["none", "none", "block"]}>
                                <img width={"147px"} src={SkypageLogo} alt={"logo"} />
                            </Grid>
                            <Grid item xs={12} mt={["0px", "0px", "50px"]}>
                                <DisplayText
                                    position={"relative"}
                                    textcolor={ColorTheme.base.white}
                                    size={desktopView ? "lg" : "md"}
                                    textweight={"bold"}
                                    textheight={desktopView ? "72px" : "42px"}
                                >
                                    Slice Pay - Payment Plans for{" "}
                                    <span style={{ color: ColorTheme.secondary[250] }}>
                                        Travel
                                        <img
                                            style={{
                                                position: "absolute",
                                                marginLeft: "-140px"
                                            }}
                                            src={CircleLap}
                                            alt={"highlight"}
                                        />
                                    </span>
                                </DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                {SkyPageTravelInfoList.map((list) => (
                                    <Box mt={"28px"} gap={"15px"} key={list.id} display={"flex"}>
                                        <Box>
                                            <img height={"32px"} width={"32px"} src={CheckIcon} alt={"check"} />
                                        </Box>
                                        <Box>
                                            <Text size={"md"} textcolor={ColorTheme.base.white} textweight={"regular"}>
                                                {list.description}
                                            </Text>
                                        </Box>
                                    </Box>
                                ))}
                            </Grid>
                            <Grid marginBottom={["50px", "50px", "100px"]} item xs={12} mt={"50px"}>
                                <Box sx={{display: "flex", flexDirection: ["column", "column", "row"]}} gap={"10px"}>
                                    <PrimaryButton
                                        sx={{
                                            width: ["100%", "100%", "225px"]
                                        }}
                                        onClick={() => {
                                            logAmplitudeEvent("UseSkyPay");
                                            windowScroll("findusatsection");
                                        }}
                                    >
                                        Use Slice Pay
                                    </PrimaryButton>

                                    <PrimaryButton
                                        sx={{
                                            width: ["100%", "100%", "225px"]
                                        }}
                                        onClick={() => {
                                            logAmplitudeEvent("GoToTravelAgent");
                                            navigate("/for-travel-agents");
                                        }}
                                    >
                                        Travel Agent?
                                    </PrimaryButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} mb={["100px", "100px", "0px"]}>
                <img
                    width={"100%"}
                    height={"100%"}
                    src={desktopView ? SkyPageDesktop : SkyPageMobile}
                    alt={"background"}
                />
            </Grid>
        </Grid>
    );
};

export default SkyPageHeroBanner;
