import { Grid } from "@mui/material";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import { howItWorkSectionList } from "../util";
import HowItWorkCard from "./HowItWorkCard";

const HowItWorksSection = () => {
    return (
        <Grid container py={"56px"} pb={"120px"} justifyContent={"center"} bgcolor={"#233243"}>
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <DisplayText textcolor={ColorTheme.base.white} size={"sm"} textweight={"bold"}>
                            How it works
                        </DisplayText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Text textcolor={ColorTheme.grayNeutral[50]} size={"md"} textweight={"regular"}>
                            Slice Pay takes on the risk of purchase and manages repayments. All you need to do is make the
                            sale using Slice Pay and we will send you additional commission once the customer finishes
                            paying off their trip.
                        </Text>
                    </Grid>
                    <Grid item my={"32px"} xs={12}>
                        <Grid
                            container
                            mt={["0px", "0px", "60px"]}
                            height={"100%"}
                            alignItems={"stretch"}
                            columnSpacing={"20px"}
                            rowSpacing={"60px"}
                        >
                            {howItWorkSectionList.map((item) => (
                                <Grid item xs={12} md={3} height={["fit-content", "fit-content", "100%"]} key={item.id}>
                                    <HowItWorkCard
                                        description={item.description}
                                        img={item.imgSrc}
                                        key={item.id}
                                        index={item.id}
                                        title={item.title}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HowItWorksSection;
