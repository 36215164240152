import { Button } from "@mui/material";
import { ColorTheme } from "../../styles/color";
import Styled from "@emotion/styled";

export const PrimaryButton = Styled(Button)`
    border-radius: 8px;
    border: 1px solid ${(props) => props.buttoncolor || ColorTheme.secondary[500]};
    background: ${(props) => props.buttoncolor || ColorTheme.secondary[500]};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: ${ColorTheme.base.white};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 56px;
    flex: 1 0 0;
    text-transform: none;
    &:hover {
        background: ${(props) => props.buttoncolor || ColorTheme.secondary[500]};
        opacity: 0.8;
    }
`;
