import { Box, Grid } from "@mui/material";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import { companyLogoList, partnershipList } from "../util";
import CheckIcon from "../assets/check.svg";
import CircleIcon from "../assets/circle_icon.svg";
import Img3 from "../assets/img3.svg";
import Img2 from "../assets/img2.svg";
import Img1 from "../assets/img1.svg";

const SkyPagePartners = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Text textAlign={"center"} size={"md"} textweight={"medium"} textcolor={ColorTheme.grayNeutral[400]}>
                    OUR PARTNERS- SOME OF THE BEST IN TRAVEL
                </Text>
            </Grid>
            <Grid item xs={12} mt={"32px"}>
                <Box gap={["20px", "20px", "97px"]} display={"flex"} justifyContent={"center"}>
                    {companyLogoList.map((item, index) => (
                        <img src={item.logo} alt={"pay-later"} key={`${item.id}-${item.logo}`} height={"33px"} />
                    ))}
                </Box>
            </Grid>
            <Grid item xs={12} py={["56px"]}>
                <Grid container alignItems={"center"} columnSpacing={"69px"}>
                    <Grid item xs={12} md={6}>
                        <Grid container columnSpacing={"15px"} rowSpacing={"15px"}>
                            <Grid item xs={12}>
                                <img
                                    style={{ borderRadius: "20px 20px 0px 0px", objectFit: "cover" }}
                                    src={Img1}
                                    height={"258px"}
                                    width={"100%"}
                                    alt={"img-1"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    style={{ borderRadius: "0px 0px 0px 20px", objectFit: "cover" }}
                                    src={Img2}
                                    height={"183px"}
                                    width={"100%"}
                                    alt={"img-2"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    style={{ borderRadius: "0px 0px 20px 0px", objectFit: "cover" }}
                                    src={Img3}
                                    height={"183px"}
                                    width={"100%"}
                                    alt={"img-3"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} mt={["40px", "40px", "0px"]} position={"relative"}>
                        <Box position={"absolute"} top={"-20px"} left={"90px"}>
                            <img src={CircleIcon} alt={"circle"} />
                        </Box>
                        <DisplayText textheight={"38px"} size={"md"} textweight={"bold"}>
                            A win-win partnership between Slice Pay and Travel Agents
                        </DisplayText>
                        <Box mt={"30px"}>
                            {partnershipList.map((item) => (
                                <Box mt={"28px"} key={item.id} gap={"24px"} display={"flex"}>
                                    <Box>
                                        <img height={"32px"} width={"32px"} src={CheckIcon} alt={"check"} />
                                    </Box>
                                    <Box>
                                        <Text size={"md"} textweight={"regular"}>
                                            {item.description}
                                        </Text>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SkyPagePartners;
