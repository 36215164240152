import { Grid } from "@mui/material";
import Gallery1 from "../assets/gallery1.png";
import Gallery2 from "../assets/gallery2.png";
import Gallery3 from "../assets/gallery3.png";
import { useGetWindowSize } from "../common/hook/WindowSize";

const Gallery = () => {
    const { desktopView } = useGetWindowSize();

    if (desktopView) {
        return (
            <Grid container my={"60px"} columnSpacing={"50px"}>
                <Grid item xs={4}>
                    <img src={Gallery1} height={"340px"} width={"100%"} alt={"galleryimg"} />
                </Grid>
                <Grid item xs={4}>
                    <img
                        src={Gallery2}
                        style={{
                            scale: "1.2"
                        }}
                        height={"340px"}
                        width={"100%"}
                        alt={"gallery2img"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <img src={Gallery3} height={"340px"} width={"100%"} alt={"gallery3img"} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={"10px"}>
            <Grid item xs={8}>
                <img src={Gallery2} height={"94%"} width={"100%"} alt={"galleryimg"} />
            </Grid>
            <Grid item xs={4}>
                <Grid container rowSpacing={"10px"}>
                    <Grid item xs={12}>
                        <img src={Gallery1} width={"100%"} alt={"gallery2img"} />
                    </Grid>
                    <Grid item xs={12}>
                        <img src={Gallery3} width={"100%"} alt={"gallery3img"} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Gallery;
