import { Box } from "@mui/material";
import { Text } from "../common/typography/Style";

const FindUsAtCard = ({ img, title, description, url, onCardClick }) => {
    return (
        <Box style={{ cursor: "pointer" }} onClick={onCardClick}>
            <img
                style={{
                    objectFit: "cover",
                    borderRadius: 20
                }}
                alt={"cover"}
                src={img}
                height={"250px"}
                width={"100%"}
            />
            <Text my={"12px"} size={"xl"} textweight={"semiBold"}>
                {title}
            </Text>
            <Text my={"12px"} size={"md"} textweight={"regular"}>
                {description}
            </Text>
        </Box>
    );
};

export default FindUsAtCard;
