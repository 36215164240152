import { Grid } from "@mui/material";
import FeaturedIcon from "../assets/featured_icon.svg";
import { Text } from "../common/typography/Style";

const TagCard = ({ title, description }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <img src={FeaturedIcon} alt={"FeaturedIcon"} />
                <Text mt={"16px"} size={"lg"} textweight={"semiBold"}>
                    {title}
                </Text>
                <Text size={"md"} mt={"8px"} textweight={"regular"}>
                    {description}
                </Text>
            </Grid>
        </Grid>
    );
};

export default TagCard;
