import { InfoSectionContainer } from "../style";
import { Grid, Box } from "@mui/material";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import { useGetWindowSize } from "../common/hook/WindowSize";

const AboutUsInfo = () => {
    const { desktopView } = useGetWindowSize();

    if (desktopView) {
        return (
            <InfoSectionContainer my={"48px"} py={"20px"} container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={4} borderRight={`1px ${ColorTheme.grayNeutral[200]} solid`}>
                            <DisplayText
                                textweight={"bold"}
                                textAlign={"center"}
                                size={"sm"}
                                textcolor={ColorTheme.base.white}
                            >
                                50,000+
                            </DisplayText>
                            <Text
                                size={"md"}
                                textcolor={ColorTheme.grayNeutral[200]}
                                textAlign={"center"}
                                textweight={"regular"}
                            >
                                Travellers
                            </Text>
                        </Grid>
                        <Grid item xs={4} borderRight={`1px ${ColorTheme.grayNeutral[200]} solid`}>
                            <DisplayText
                                textweight={"bold"}
                                textAlign={"center"}
                                size={"sm"}
                                textcolor={ColorTheme.base.white}
                            >
                                190+
                            </DisplayText>
                            <Text
                                size={"md"}
                                textcolor={ColorTheme.grayNeutral[200]}
                                textAlign={"center"}
                                textweight={"regular"}
                            >
                                Destinations
                            </Text>
                        </Grid>
                        <Grid item xs={4}>
                            <DisplayText
                                textweight={"bold"}
                                textAlign={"center"}
                                size={"sm"}
                                textcolor={ColorTheme.base.white}
                            >
                                $10 million+
                            </DisplayText>
                            <Text
                                size={"md"}
                                textcolor={ColorTheme.grayNeutral[200]}
                                textAlign={"center"}
                                textweight={"regular"}
                            >
                                in total savings for customers
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </InfoSectionContainer>
        );
    }

    return (
        <Box>
            <InfoSectionContainer my={"48px"} py={"20px"} container>
                <Grid item xs={6}>
                    <DisplayText
                        textheight={"48px"}
                        textweight={"bold"}
                        textAlign={"center"}
                        size={"sm"}
                        textcolor={ColorTheme.base.white}
                    >
                        50,000+
                    </DisplayText>
                    <Text
                        size={"md"}
                        textcolor={ColorTheme.grayNeutral[200]}
                        textAlign={"center"}
                        textweight={"regular"}
                    >
                        Travellers
                    </Text>
                </Grid>
                <Grid item xs={6}>
                    <DisplayText
                        textheight={"48px"}
                        textweight={"bold"}
                        textAlign={"center"}
                        size={"sm"}
                        textcolor={ColorTheme.base.white}
                    >
                        190+
                    </DisplayText>
                    <Text
                        size={"md"}
                        textcolor={ColorTheme.grayNeutral[200]}
                        textAlign={"center"}
                        textweight={"regular"}
                    >
                        Destinations
                    </Text>
                </Grid>
            </InfoSectionContainer>
            <InfoSectionContainer my={"48px"} py={"10px"} container>
                <Grid item xs={12}>
                    <DisplayText
                        textheight={"48px"}
                        textweight={"bold"}
                        textAlign={"center"}
                        size={"sm"}
                        textcolor={ColorTheme.base.white}
                    >
                        $10M+
                    </DisplayText>
                    <Text
                        size={"md"}
                        textcolor={ColorTheme.grayNeutral[200]}
                        textAlign={"center"}
                        textweight={"regular"}
                    >
                        in total savings for customers
                    </Text>
                </Grid>
            </InfoSectionContainer>
        </Box>
    );
};

export default AboutUsInfo;
