import { Box, Grid } from "@mui/material";
import skyLogo from "../assets/logo-jetblack.svg";
import { Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";

const Footer = () => {
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={6}>
                <Box display={"flex"} justifyContent={"center"}>
                    <img src={skyLogo} alt={"skylogo"} height={"80px"} />
                </Box>
                <Text
                    mt={"12px"}
                    textcolor={ColorTheme.grayNeutral[700]}
                    textAlign={"center"}
                    size={"md"}
                    textweight={"regular"}
                >
                    Slice Pay partners with travel agents to offer lay by to travellers.
                </Text>
                <Text
                    mt={"32px"}
                    textcolor={ColorTheme.grayNeutral[700]}
                    textAlign={"center"}
                    size={"md"}
                    textweight={"regular"}
                >
                    Copyright © 2024 Slice Pay
                </Text>
            </Grid>
        </Grid>
    );
};

export default Footer;
