import Styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ColorTheme } from "../../styles/color";

// XL ----- Display

export const DisplayText = Styled(Typography)`
    font-family: Inter !important;
    font-size: 60px !important;
    font-style: normal !important;
    line-height: ${(props) => props.textheight || "72px"} !important;
    letter-spacing:  ${(props) => props.textspacing || "-1.2px"}  !important;
    color: ${(props) => props.textcolor || "#101828"}  !important;
    ${(props) =>
        props.size === "xl" &&
        `font-size: 60px !important;
    `};
    ${(props) =>
        props.size === "lg" &&
        `font-size: 48px !important;
    `};
    ${(props) =>
        props.size === "md" &&
        `
    font-size: 38px !important;
    `};
    ${(props) =>
        props.size === "sm" &&
        `font-size: 30px !important;
    `};
    ${(props) =>
        props.size === "xs" &&
        `font-size: 24px !important;
    `};
    ${(props) =>
        props.size === "xxs" &&
        `font-size: 10px !important;
    `};
    ${(props) =>
        props.textsize &&
        `font-size: ${props.textsize} !important;
    `};
    ${(props) =>
        props.textweight === "regular" &&
        `font-weight: 400 !important;
    `};
    ${(props) =>
        props.textweight === "medium" &&
        `font-weight: 500 !important;
    `};
    ${(props) => props.textweight === "semiBold" && `font-weight: 600 !important;`};
    ${(props) => props.textweight === "bold" && `font-weight: 700 !important;`};
    
    ${(props) => props.textweight === "extraBold" && `font-weight: 800 !important;`};
`;

export const DisplayXlRegular = Styled(DisplayText)`
    font-weight: 400 !important;
`;

export const DisplayXlMedium = Styled(DisplayText)`
    font-weight: 500 !important;
`;

export const DisplayXlSemiBold = Styled(DisplayText)`
    font-weight: 600 !important;
`;

export const DisplayXlBold = Styled(DisplayText)`
    font-weight: 700 !important;
`;

export const DisplayXlExtraBold = Styled(DisplayText)`
    font-weight: 800 !important;
`;

// Lg ----- Display

const DisplayLg = Styled(Typography)`
    font-family: Inter !important;
    font-size: 48px !important;
    font-style: normal !important;
    line-height: 56px !important; /* 120% */
    letter-spacing: -0.96px !important;
    color: ${(props) => props.textcolor || "#101828"}  !important;
`;

export const DisplayLgRegular = Styled(DisplayLg)`
    font-weight: 400 !important;
`;

export const DisplayLgMedium = Styled(DisplayLg)`
    font-weight: 500 !important;
`;

export const DisplayLgSemiBold = Styled(DisplayLg)`
    font-weight: 600 !important;
`;

export const DisplayLgBold = Styled(DisplayLg)`
    font-weight: 700 !important;
`;

export const DisplayLgExtraBold = Styled(DisplayLg)`
    font-weight: 800 !important;
`;

// Md ----- Display

const DisplayMd = Styled(Typography)`
    font-family: Inter !important;
    font-size: 36px !important;
    font-style: normal !important;
    line-height: 42px !important; /* 120% */
    letter-spacing: -0.72px !important;
    color: ${(props) => props.textcolor || "#101828"}  !important;
`;

export const DisplayMdRegular = Styled(DisplayMd)`
    font-weight: 400 !important;
`;

export const DisplayMdMedium = Styled(DisplayMd)`
    font-weight: 500 !important;
`;

export const DisplayMdSemiBold = Styled(DisplayMd)`
    font-weight: 600 !important;
`;

export const DisplayMdBold = Styled(DisplayMd)`
    font-weight: 700 !important;
`;

export const DisplayMdExtraBold = Styled(DisplayMd)`
    font-weight: 800 !important;
`;

// sm ----- Display

const DisplaySm = Styled(Typography)`
    font-family: Inter !important;
    font-size: 30px !important;
    font-style: normal !important;
    line-height: 38px !important;
    color: ${(props) => props.textcolor || "#101828"}  !important;
`;

export const DisplaySmRegular = Styled(DisplaySm)`
    font-weight: 400 !important;
`;

export const DisplaySmMedium = Styled(DisplaySm)`
    font-weight: 500 !important;
`;

export const DisplaySmSemiBold = Styled(DisplaySm)`
    font-weight: 600 !important;
`;

export const DisplaySmBold = Styled(DisplaySm)`
    font-weight: 700 !important;
`;

export const DisplaySmExtraBold = Styled(DisplaySm)`
    font-weight: 800 !important;
`;

// xs ----- Display

const DisplayXs = Styled(Typography)`
    font-family: Inter !important;
    font-size: 30px !important;
    font-style: normal !important;
    line-height: 38px !important;
    color: ${(props) => props.textcolor || "#101828"}  !important;
`;

export const DisplayXsRegular = Styled(DisplayXs)`
    font-weight: 400 !important;
`;

export const DisplayXsMedium = Styled(DisplayXs)`
    font-weight: 500 !important;
`;

export const DisplayXsSemiBold = Styled(DisplayXs)`
    font-weight: 600 !important;
`;

export const DisplayXsBold = Styled(DisplayXs)`
    font-weight: 700 !important;
`;

export const DisplayXsExtraBold = Styled(DisplayXs)`
    font-weight: 800 !important;
`;

export const Text = Styled(Typography)`
    color: ${(props) => props.textcolor || "#101828"}  !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    ${(props) =>
        props.size === "xl" &&
        `font-size: 20px !important;
    `};
    ${(props) =>
        props.size === "lg" &&
        `font-size: 18px !important;
    `};
    ${(props) =>
        props.size === "md" &&
        `
    font-size: 16px !important;
    `};
    ${(props) =>
        props.size === "sm" &&
        `font-size: 14px !important;
    `};
    ${(props) =>
        props.size === "xs" &&
        `font-size: 12px !important;
    `};
    ${(props) =>
        props.textweight === "regular" &&
        `font-weight: 400 !important;
    `};
    ${(props) =>
        props.textweight === "medium" &&
        `font-weight: 500 !important;
    `};
    ${(props) => props.textweight === "semiBold" && `font-weight: 600 !important;`};
    ${(props) => props.textweight === "bold" && `font-weight: 700 !important;`};
    
    ${(props) => props.textweight === "extraBold" && `font-weight: 800 !important;`};
`;

export const LinkText = Styled.span`
    color: ${(props) => props.textcolor || ColorTheme.primary[400]} !important;    font-family: Inter !important;
    font-size: ${(props) => props.textsize || "14px"}  !important;
    font-style: normal !important;
    font-weight: ${(props) => props.textweight || 600} !important;
    line-height: 20px !important;
    text-decoration-line: ${(props) => (props.none ? "none" : "underline")}  !important;
    cursor: pointer;
`;
