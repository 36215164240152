import { Grid } from "@mui/material";
import { ColorTheme } from "../styles/color";
import { HowItWorkSectionInfo } from "../util";
import HowItWorkCard from "./HowItWorkCard";
import { DisplayText, Text } from "../common/typography/Style";

const HowItWorks = () => {
    return (
        <Grid container my={"40px"} px={"20px"} mb={"120px"}>
            <Grid item xs={12}>
                <DisplayText
                    textAlign={["center", "center", "left"]}
                    textcolor={ColorTheme.base.black}
                    size={"sm"}
                    textweight={"bold"}
                >
                    How it <span style={{ color: ColorTheme.secondary[600] }}>Works</span>
                </DisplayText>
                <Text
                    textAlign={["center", "center", "left"]}
                    textcolor={ColorTheme.grayNeutral[700]}
                    size={"md"}
                    textweight={"regular"}
                >
                    Slice Pay helps you lock in today’s flight price for a small deposit and pay off the remaining
                    amount before you depart.
                </Text>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    mt={["0px", "0px", "30px"]}
                    height={"100%"}
                    alignItems={"stretch"}
                    columnSpacing={"20px"}
                    rowSpacing={"60px"}
                    px={["15px", "15px", "0px"]}
                >
                    {HowItWorkSectionInfo.map((item) => (
                        <Grid item xs={12} md={3} height={["fit-content", "fit-content", "100%"]} key={item.id}>
                            <HowItWorkCard
                                description={item.description}
                                img={item.icon}
                                key={item.id}
                                index={item.id}
                                title={item.title}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HowItWorks;
