import { Grid } from "@mui/material";
import SkyPageHeroBanner from "./component/SkyPageHeroBanner";
import HowItWorks from "./component/HowItWorks";
import FindUsAtSection from "./component/FindUsAtSection";
import Footer from "./component/Footer";
import FaqSection from "./component/FaqSection";
import AboutUs from "./component/AboutUs";
import { useEffect } from "react";
import { logAmplitudeEvent } from "./log-amplitude-event";

const SkyPageCustomer = () => {
    useEffect(() => {
        logAmplitudeEvent("Customer views the page");
    }, []);

    return (
        <Grid container justifyContent={"center"} className="page-skypay-for-customers">
            <Grid item xs={12}>
                <SkyPageHeroBanner />
            </Grid>
            <Grid item xs={12} bgcolor={"#E9F8FB"}>
                <Grid container justifyContent={"center"}>
                    <Grid item maxWidth={"1084px"}>
                        <HowItWorks />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                    <Grid item px={["20px", "20px", "0px"]} maxWidth={"1084px"} id={"findusatsection"}>
                        <FindUsAtSection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"60px"} py={"64px"} bgcolor={"#1F2A37"}>
                <Grid container justifyContent={"center"}>
                    <Grid item px={["20px", "20px", "0px"]} maxWidth={"1084px"}>
                        <AboutUs />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"50px"}>
                <Grid container justifyContent={"center"}>
                    <Grid item px={["20px", "20px", "0px"]} maxWidth={"1084px"} width={"100%"}>
                        <FaqSection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item px={["20px", "20px", "0px"]} xs={12} py={"56px"} mt={"40px"} bgcolor={"#E9F8FB"}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default SkyPageCustomer;
