import { Grid } from "@mui/material";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import CircleLap from "../assets/skypageCircle.svg";
import AboutUsInfo from "./AboutUsInfo";
import Gallery from "./Gallery";
import { useGetWindowSize } from "../common/hook/WindowSize";

const AboutUs = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Text textAlign={["left", "left", "center"]} textcolor={ColorTheme.grayNeutral[100]} size={"xl"}>
                    ABOUT US
                </Text>
            </Grid>
            <Grid item xs={12} mt={"10px"}>
                <DisplayText
                    position={"relative"}
                    textAlign={["left", "left", "center"]}
                    textcolor={ColorTheme.base.white}
                    size={"md"}
                    textheight={desktopView ? "72px" : "42px"}
                    textweight={"bold"}
                >
                    Making travel{" "}
                    <span style={{ color: ColorTheme.secondary[250] }}>
                        accessible
                        <img
                            style={{
                                position: "absolute",
                                marginLeft: "-190px"
                            }}
                            src={CircleLap}
                            alt={"highlight"}
                        />
                    </span>
                </DisplayText>
            </Grid>
            <Grid item xs={12} mt={["30px", "30px", "0px"]}>
                <Text textAlign={["left", "left", "center"]} textcolor={ColorTheme.base.white} size={"md"}>
                    The Slice Pay team has already helped thousands of customers travel.
                </Text>
            </Grid>
            <Grid item xs={12}>
                <AboutUsInfo />
            </Grid>
            <Grid item xs={12}>
                <Gallery />
            </Grid>
        </Grid>
    );
};

export default AboutUs;
