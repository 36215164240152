import { object, string } from "yup";
import AeroLogo from "./assets/aero_logo.svg";
import AlatusLogo from "./assets/alatus_logo.svg";
import ExpressLogo from "./assets/express_logo.svg";
import SkyPayCheckout from "./assets/skypage_checkout.svg";
import SkyPayEarn from "./assets/skypage_earn.svg";
import SkyPayGoods from "./assets/skypage_goods.svg";
import SkyPaylater from "./assets/skypage_paylater.svg";

export const skyPageTagLine = [
    {
        id: 1,
        title: "Increase Your Number of Sales",
        description:
            "The high upfront cost of travel makes it inaccessible to some. By offering instalment plans to your customers Slice Pay empowers you to make more sales."
    },
    {
        id: 2,
        title: "Earn more per sale",
        description:
            "For every sale put through Slice Pay you earn an additional commission on-top of whatever you receive from suppliers."
    }
];

export const howItWorkSectionList = [
    {
        id: 1,
        title: "Offer Slice Pay at checkout",
        description:
            "Offer your customers the option to pay using a Slice Pay payment plan. They can purchase their trip by putting down a deposit.",
        imgSrc: SkyPayCheckout
    },
    {
        id: 2,
        title: "We pay for the goods",
        description: "Slice Pay pays for the goods on your behalf. You keep the TTV and supplier commission.",
        imgSrc: SkyPayGoods
    },
    {
        id: 3,
        title: "Customer pays off instalment plan",
        description:
            "Customer pays Slice Pay in weekly or biweekly instalments. We handle all the communication to the customer about payments so you don’t need to worry about it.",
        imgSrc: SkyPaylater
    },
    {
        id: 4,
        title: "Earn More Per Sale",
        description:
            "For every sale put through Slice Pay you earn an additional commission on-top of whatever you receive from suppliers. Once the customer completes their payment plan we pay you additional commission for each sale which uses Slice Pay. So you earn more!",
        imgSrc: SkyPayEarn
    }
];

export const companyLogoList = [
    {
        id: 1,
        logo: AlatusLogo
    },
    {
        id: 2,
        logo: ExpressLogo
    },
    {
        id: 3,
        logo: AeroLogo
    }
];

export const partnershipList = [
    {
        id: 1,
        description: "Travel Agents who partner with Slice Pay earn more revenue through a unique commission structure."
    },
    {
        id: 2,
        description:
            "Travel Agents who partner with Slice Pay see improved conversion rates and larger basket sizes by offering Slice Pay to their customers."
    },
    {
        id: 3,
        description:
            "Slice Pay takes on the credit risk; handling the processing of payments, leaving Travel Agents to do what they do best."
    }
];
export const joinNowValidationSchema = object().shape({
    email: string().email("Please enter valid email").required("Please enter email"),
    name: string().required("Please enter name")
});
