import { Box, Grid } from "@mui/material";
import { useState } from "react";
import SkyPageHeaderImg from "../assets/skypage_section.svg";
import { PrimaryButton } from "../common/button/ButtonStyle";
import { DisplayText, Text } from "../common/typography/Style";
import { skyPageTagLine } from "../util";
import OpenJoinNowModal from "./OpenJoinNowModal";
import TagCard from "./TagCard";

const SkyPageHeader = () => {
    const [openJoinNowModal, setOpenJoinNow] = useState(false);

    return (
        <Grid container columnSpacing={"24px"} mt={"16px"}>
            <Grid item xs={12} md={6}>
                <DisplayText size={"lg"} textweight={"bold"}>
                    Make more sales.
                </DisplayText>
                <DisplayText size={"lg"} textweight={"bold"}>
                    Earn more commission
                </DisplayText>
                <Text mt={"32px"} size={"md"} textweight={"regular"}>
                    Slice Pay partners with travel agents to improve conversion rates and revenue. Our platform enables
                    travel agents to offer a lay-by at the point of sale, enabling their customers to break up the cost
                    of travel into weekly or fortnightly instalments all before travel. With Slice Pay, there's no
                    interest and no credit checks, making travel fair and accessible for all.
                </Text>
                <Box mt={"32px"}>
                    <PrimaryButton onClick={() => setOpenJoinNow(true)} fullWidth variant={"contained"}>
                        Join now!
                    </PrimaryButton>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={["none", "none", "block"]}>
                <img src={SkyPageHeaderImg} style={{ objectFit: "contain" }} alt={"Slice Pay"} />
            </Grid>
            <Grid item xs={12} mt={"64px"}>
                <Grid container columnSpacing={"128px"}>
                    {skyPageTagLine.map((item) => (
                        <Grid item xs={12} md={6} key={item.id}>
                            <TagCard description={item.description} title={item.title} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {openJoinNowModal && <OpenJoinNowModal handleClose={() => setOpenJoinNow(false)} />}
        </Grid>
    );
};

export default SkyPageHeader;
