import { Box, Grid } from "@mui/material";
import FaqBackground from "../assets/faq_background.png";
import { PrimaryButton } from "../common/button/ButtonStyle";
import { DisplayText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import { logAmplitudeEvent } from "../util/logAmplitudeEvent";

const FaqSection = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    style={{
                        backgroundImage: `url(${FaqBackground})`,
                        padding: 54,
                        borderRadius: 40,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <Box top={0} width={"100%"} display={"flex"} justifyContent={"center"}>
                        <Box>
                            <DisplayText
                                textAlign={"center"}
                                size={"sm"}
                                textheight={"38px"}
                                textcolor={ColorTheme.base.white}
                                textweight={"bold"}
                            >
                                Frequently asked <span style={{ color: ColorTheme.secondary[600] }}>questions</span>
                            </DisplayText>
                            <Text
                                size={"md"}
                                mt={"12px"}
                                textAlign={"center"}
                                textcolor={ColorTheme.base.white}
                                textweight={"regular"}
                            >
                                Everything you need to know to get started offering Slice Pay to your customers.
                            </Text>

                            <Box display={"flex"} mt={"25px"} justifyContent={"center"}>
                                <Box width={"180px"}>
                                    <PrimaryButton
                                        onClick={() => {
                                            logAmplitudeEvent("sky-page-faq-clicked");
                                            window.open(
                                                "https://paylatertravel.notion.site/7c3a0d641e9d4ec2a89cd5058140e7a0?v=492c8e40e0674e8f985d20c7e6de14d4",
                                                "_blank"
                                            );
                                        }}
                                        style={{ width: "180px" }}
                                    >
                                        View FAQs
                                    </PrimaryButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FaqSection;
