import { useField } from "formik";
import { ColorTheme } from "../../styles/color";
import { Text } from "../typography/Style";
import { Box, TextField } from "@mui/material";

const FormikInputField = ({
    name,
    fieldLabel,
    id,
    label,
    isMandatory,
    inputType,
    onTextfieldBlur,
    amplitudeData,
    redesign,
    ...rest
}) => {
    const [field, { error, touched }] = useField(name);

    return (
        <Box>
            {label && (
                <Text textcolor={ColorTheme.grayNeutral[700]} size={"sm"} textweight={"regular"}>
                    {label} {isMandatory && <span className="text-danger">*</span>}
                </Text>
            )}
            <TextField
                {...field}
                size="medium"
                error={!!error && !!touched}
                helperText={!!error && !!touched && error}
                {...rest}
            />
        </Box>
    );
};

export default FormikInputField;
