import { Grid } from "@mui/material";
import { FindUsAtLocationList } from "../util";
import FindUsAtCard from "./FindUsAtCard";
import { ColorTheme } from "../styles/color";
import { logAmplitudeEvent } from "../log-amplitude-event";
import { DisplayText } from "../common/typography/Style";

const FindUsAtSection = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <DisplayText textcolor={ColorTheme.base.black} size={"sm"} textweight={"bold"}>
                    Find Us At
                </DisplayText>
            </Grid>
            <Grid item xs={12} mt={"24px"}>
                <Grid container columnSpacing={"20px"}>
                    {FindUsAtLocationList.map((loc) => (
                        <Grid item xs={12} md={4} key={loc.id}>
                            <FindUsAtCard
                                url={loc.url}
                                description={loc.description}
                                img={loc.imgSrc}
                                title={loc.title}
                                onCardClick={() => {
                                    logAmplitudeEvent("Click Travel Agent", {
                                        value: loc.title
                                    });
                                    window.open(loc.url, "_blank");
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FindUsAtSection;
