import Styled from "@emotion/styled";
import { Box } from "@mui/material";

export const HowItWorkCardContainer = Styled(Box)`
    border-radius: 16px;
    border: 1px solid var(--Primary-300, #A8E5EF);
    background: var(--Primary-25, #F4FDFF);
    height: 100%;
    position: relative;
`;

export const GlowingIcon = Styled(Box)`
border-radius: 50%;
border: 6px solid rgba(121, 186, 107, 0.20);
background: var(--Secondary-500, #58A946);
display: flex;
height: 32px;
width: 32px;
justify-content: center;
align-items: center;
position: absolute;
top: -20px;
box-shadow: -1px 6px 13px 9px rgba(121, 186, 107, 0.20);
`;
