import { HowItWorkCardContainer } from "../style";
import { Box } from "@mui/material";
import ArrowRightCurveIcon from "../assets/arrow-right-curve.svg";
import ArrowCurveIcon from "../assets/arrow-curve-icon.svg";
import ArrowLeftIcon from "../assets/arrow-left-curve.svg";
import { useGetWindowSize } from "../common/hook/WindowSize";
import { ColorTheme } from "../styles/color";
import { Text } from "../common/typography/Style";

const HowItWorkCard = ({ title, description, img, index }) => {
    const { desktopView } = useGetWindowSize();

    const renderIcon = () => {
        if (desktopView && index !== 4) {
            return (
                <Box
                    display={"flex"}
                    position={"absolute"}
                    zIndex={9999}
                    bottom={index !== 2 ? "-38px" : undefined}
                    top={index === 2 ? "-36px" : undefined}
                    right={"-50px"}
                    justifyContent={"flex-end"}
                >
                    <img
                        style={{
                            transform: index === 2 ? "rotateX(180deg)" : ""
                        }}
                        src={ArrowCurveIcon}
                        alt={"arrow_curve"}
                    />
                </Box>
            );
        }

        return (
            <Box
                display={index === 4 ? "none" : "flex"}
                position={"absolute"}
                zIndex={9999}
                width={"100%"}
                left={"0px"}
                marginTop={"20px"}
                justifyContent={index % 2 === 0 ? "flex-end" : "flex-start"}
            >
                <img src={index % 2 === 0 ? ArrowLeftIcon : ArrowRightCurveIcon} alt={"arrow_curve"} />
            </Box>
        );
    };
    return (
        <HowItWorkCardContainer position={"relative"} px={"30px"} py={["30px", "30px", "0px"]}>
            {desktopView && renderIcon()}
            <Box display={"flex"} position={"absolute"} top={-40} left={0} width={"100%"} justifyContent={"center"}>
                <img src={img} alt={title} />
            </Box>
            <Box pt={["30px", "30px", "70px"]} pb={["40px", "40px", "0px"]}>
                <Text textAlign={"center"} size={"xl"} textweight={"semiBold"}>
                    {title}
                </Text>
                <Text
                    mt={"10px"}
                    textcolor={ColorTheme.grayNeutral[700]}
                    textAlign={"center"}
                    size={"md"}
                    textweight={"regular"}
                >
                    {description}
                </Text>
            </Box>
            {!desktopView && renderIcon()}
        </HowItWorkCardContainer>
    );
};

export default HowItWorkCard;
