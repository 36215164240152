import { Box, Dialog, Grid } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import skyLogo from "../assets/logo-blue.svg";
import { PrimaryButton } from "../common/button/ButtonStyle";
import FormikInputField from "../common/fields/FormikInputField";
import { DisplayText, LinkText, Text } from "../common/typography/Style";
import { ColorTheme } from "../styles/color";
import { joinNowValidationSchema } from "../util";
import { logAmplitudeEvent } from "../util/logAmplitudeEvent";

const OpenJoinNowModal = ({ handleClose }) => {
    const [isSubmittedSuccessfully, setIsSubmittedSuccessFully] = useState(false);

    const renderView = () => {
        if (!isSubmittedSuccessfully) {
            return (
                <Grid item xs={12}>
                    <Text textcolor={ColorTheme.grayNeutral[600]} size={"sm"} textweight={"regular"}>
                        Enter you name and email address below to get started
                    </Text>
                    <Formik
                        initialValues={{
                            name: "",
                            email: ""
                        }}
                        validationSchema={joinNowValidationSchema}
                        onSubmit={(values) => {
                            setIsSubmittedSuccessFully(true);
                            logAmplitudeEvent("sky-page-join-now", {
                                name: values.name,
                                email: values.email,
                                when: new Date().toLocaleString()
                            });
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Grid container mt={"10px"} columnSpacing={"10px"}>
                                <Grid item xs={12}>
                                    <FormikInputField
                                        redesign
                                        fullWidth
                                        name={"name"}
                                        placeholder={"Enter your name"}
                                    />
                                </Grid>
                                <Grid item xs={12} mt={"10px"}>
                                    <FormikInputField
                                        fullWidth
                                        redesign
                                        placeholder={"Enter your company email address"}
                                        name={"email"}
                                    />
                                </Grid>
                                <Grid item xs={12} mt={"10px"}>
                                    <PrimaryButton onClick={handleSubmit} fullWidth>
                                        Next
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Grid>
            );
        }

        return (
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            display={"flex"}
                            flexDirection={["column", "column", "row"]}
                            justifyContent={"center"}
                            gap={"10px"}
                        >
                            <DisplayText textheight={"48px"} textAlign={["center"]} textweight={"bold"} size={"sm"}>
                                Your email has been registered
                            </DisplayText>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mt={"30px"}>
                        <Text textcolor={ColorTheme.grayNeutral[600]} size={"sm"} textweight={"regular"}>
                            Thank you for choosing Slice Pay for your business.
                        </Text>
                        <Text mt={"8px"} textcolor={ColorTheme.grayNeutral[600]} size={"sm"} textweight={"regular"}>
                            We've received your email address, and our team will be in touch soon to onboard you.
                        </Text>
                        <Text mt={"8px"} textcolor={ColorTheme.grayNeutral[600]} size={"sm"} textweight={"regular"}>
                            If you have further questions, please visit our{" "}
                            <LinkText
                                onClick={() => {
                                    logAmplitudeEvent("sky-page-faq-clicked");
                                    window.open(
                                        "https://paylatertravel.notion.site/7c3a0d641e9d4ec2a89cd5058140e7a0?v=492c8e40e0674e8f985d20c7e6de14d4",
                                        "_blank"
                                    );
                                }}
                                textcolor={ColorTheme.secondary[400]}
                            >
                                FAQ
                            </LinkText>
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    return (
        <Dialog open maxWidth={"sm"} fullWidth onClose={handleClose}>
            <Grid container p={["20px", "20px", "40px"]}>
                <Grid mb={"30px"} item xs={12}>
                    <Box display={"flex"} justifyContent={"center"} mb={"16px"}>
                        <img src={skyLogo} alt={"skylogo"} height={"80px"} />
                    </Box>
                    <DisplayText
                        mt={["30px", "30px", "0px"]}
                        textheight={"48px"}
                        textAlign={"center"}
                        size={"xs"}
                        textweight={"semiBold"}
                    >
                        Get Slice Pay for your business
                    </DisplayText>
                </Grid>
                {renderView()}
            </Grid>
        </Dialog>
    );
};

export default OpenJoinNowModal;
